import { connectURLSearchParams } from "../../../../redux/connectors/URLSearchParamConnector";
import { setParams, getParams } from "../../../helpers/params";
import { TABS } from "../../../ComponentLibrary/layout/FilterableList/FilterableListContainer";

const MAP = 'map'
const LIST = 'list'
const VIEWS = { MAP, LIST }
export { VIEWS }

// just a helper to keep the logic strait
// active and default are both variable
// PRIMARY always means LIST though.
const Tabs = (defaultView) => ({
  defaultView,
  defaultTab: function() {
    return this.viewToTab(this.defaultView)
  },
  activeTab({ filterScope }) {
    const params = getParams();
    const scopedParams = filterScope ? params[filterScope] : params;
    return scopedParams?.[TABS.TAB_PARAM] || this.defaultTab();
  },
  activeView({ filterScope }) {
    return this.tabToView(this.activeTab({ filterScope }));
  },
  viewToTab: function(view) {
    return {
      [VIEWS.LIST]: TABS.PRIMARY,
      [VIEWS.MAP]: TABS.SECONDARY,
    }[view]
  },
  tabToView: function(tab) {
    return {
      [TABS.PRIMARY]: VIEWS.LIST,
      [TABS.SECONDARY]: VIEWS.MAP
    }[tab]
  },
  setView: function(view) {
    const tab = this.viewToTab(view)
    this.setTab(tab)
  },
  setTab(tab, filterScope) {
    const scopedParams = filterScope
      ? { [filterScope]: { [TABS.TAB_PARAM]: tab } }
      : { [TABS.TAB_PARAM]: tab };
    const nextParams = { ...getParams(), ...scopedParams };
    setParams(nextParams)
  },
  TABS,
  VIEWS,
})

export default Tabs
