import { useRef, useState, useEffect, forwardRef } from 'react';
import { define } from '@owenscorning/pcb.alpha';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../../themes';
import _ from 'lodash';

// Helpers
import DeltaRenderer from '../../../helpers/delta_renderer';

// Styled Components
const Spacer = styled.span`
  background-color: #D1D1D1;
  display: inline-block;
  height: 20px;
  position: relative;
  width: 1px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  colgroup col {
    min-width: 32px;
  }

  td, th {
    border: 1px solid ${({ UI }) => UI.Theme.colors.greyTint2};
    padding: 8px;
    vertical-align: baseline;
  }

  thead {
    border-color: ${({ UI }) => UI.Theme.colors.greyTint2};
    border-style: solid;
    border-width: 2px 0;
  }

  tbody {
    tr {
      background-color: #FFFFFF;

      &:nth-child(even) {
        background-color: ${({ UI }) => UI.Theme.colors.greyBackWeb};
      }
    }

    td {
      &.selected:before {
        background-color: #B9EEFF;
        content: "";
        height: 100%;
        left: 0;
        opacity: 0.3;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .cell-content {
        position: relative;
      }
    }
  }

  tr td:first-child, tr th:first-child { border-left: 0; }
  tr td:last-child, tr th:last-child { border-right: 0; }
`;

const IconWrapper = styled.div`
  align-items: center;
  border-radius: 2px;
  color: #000000;
  cursor: pointer;
  display: inline-flex;
  height: 24px;
  justify-content: center;
  position: relative;
  width: 24px;

  &:hover, &.active {
    background-color: #E6E6E6;
  }

  * {
    display: inline-flex;
    pointer-events: none;
    position: relative;
    user-select: none;
  }
`;

const BeltOption = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  user-select: none;

  &:hover, &.active {
    background-color: #E6E6E6;
  }
`;

const ColorPreview = styled.span`
  background-color: ${props => props.color};
  border-radius: 2px;
  border: 1px solid;
  display: inline-block;
  height: 16px;
  margin-left: 8px;
  width: 16px;

  ${props => !props.color && `
    &:after {
      background: linear-gradient(
        to top left,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0) calc(50% - 1px),
        rgba(0,0,0,1) 50%,
        rgba(0,0,0,0) calc(50% + 1px),
        rgba(0,0,0,0) 100%
      );
      content: "";
      display: inline-block;
      height: 100%;
      position: relative;
      width: 100%;
    }
  `}
`;

const Toolbar = ({ identifier, tools }) => (
  <UI.Flyout css={css` padding: 3px 4px; `} position={{ y: 5 }}>
    <UI.Text.Rich.Toolbar identifier={identifier} tools={tools} bare />
  </UI.Flyout>
);

const Cell = ({ area, x, y, content, style = {}, onChange, merge, editable, selection, onSelect, editing, onEdit, container }) => {
  const Component = Cell.Component[area];
  const ref = useRef(null);

  const from = selection?.from;
  const to = selection?.to;

  const position = { x: ref.current?.offsetLeft + (ref.current?.offsetWidth / 2), y: ref.current?.offsetTop };

  const edit = {
    selected: editable && selection && from && to ? x >= from.x && x <= to.x && y >= from.y && y <= to.y : false,
    focus: editable && _.isEqual(editing, { area, x, y }),
    events: editable && {
      onClick: (event) => !event.focus && event.detail >= 2 && onEdit?.({ area, x, y }),
      onMouseDown: () => !edit.focus && onSelect({
        area, position,
        pin: { x, y },
        target: { x, y },
        active: true
      }),
      onMouseUp: () => !edit.focus && onSelect({
        ...selection,
        active: false
      }),
      onMouseEnter: () => selection?.active && onSelect({
        ...selection,
        target: { x, y },
        position: {
          x: Math.min(selection.position.x, position.x),
          y: Math.min(selection.position.y, position.y)
        }
      })
    }
  };

  let cellClasses = ["cell-parent"];
  if (area != 'border' && edit.selected) cellClasses.push("selected");

  return (
    <Component
      className={cellClasses.join(" ")}
      ref={ref}
      css={[
        css`position: relative;`,
        ..._.values(_.map(style, (value, key) => {
          const styling = {
            align: () => css` text-align: ${value}; `,
            backgroundColor: () => css` background-color: ${value}; `,
            bold: () => css` font-weight: bold; `,
            italic: () => css` font-style: italic; `,
            pink: () => css` color: ${Theme.colors.brand}; `,
            caps: () => css` text-transform: uppercase;`,
          }[key]();

          if (key == "backgroundColor") {
            return css`
              &.cell-parent { ${styling} }
            `;
          } else {
            return css`
              .cell-content { ${styling} }
            `;
          }
        }))
      ]}
      colSpan={_.isNumber(merge?.x) ? merge.x + 1 : undefined}
      rowSpan={_.isNumber(merge?.y) ? merge.y + 1 : undefined}
      {...(edit.events || {})}
    >
      {edit.focus ?
        <UI.Text.Rich
          MODE="edit"
          UI={UI}
          Board={Board}
          value={_.isPlainObject(content) ? content.ops : content}
          tools={['bold', 'italic', 'superscript', 'subscript', 'pink', 'spacer', 'link']}
          Toolbar={Toolbar}
          onKeyDown={(e) => { if (e.key === "Tab") return }}
          onChange={delta => {
            if (delta.ops[0].insert !== "\t\n") onChange(_.mapValues(delta))
          }}
          startSelected
          bare
        />
      : <>
          {_.isPlainObject(content) ?
            <div className="cell-content" css={UI.Text.Rich.Theme.base}>
              <div className="ql-editor">
                <UI.Text.Parsing content={new DeltaRenderer(content).render()} />
              </div>
            </div>
          : <span className="cell-content">{content}</span>}
        </>
      }
    </Component>
  );
};

const Rows = ({ head, body, editable, selection, onSelect, editing, onEdit, content, onChange, prior, before, container }) => {
  const area = _.reduce({ head, body }, (result, value, key) => value ? key : result, 'body');
  const Container = Rows.Container[area];
  const merges = [];

  return (
    <Container>
      {content.map((cells, y) => (
        <tr>
          {editable && <Border.Row y={before + y} selection={selection} onSelect={onSelect} />}
          {cells.map((cell, x) => {
            for (const index in merges) {
              const merging = merges[index];
              if (x >= merging.from.x && x <= merging.to.x && y >= merging.from.y && y <= merging.to.y) return null;
            }

            if (cell.merge) merges.push({
              from: { x, y },
              to: { x: x + (cell.merge.x || 0), y: y + (cell.merge.y || 0) }
            });

            return (
              <Cell
                x={x}
                y={y + prior}
                area={area}
                editable={editable}
                selection={!editing ? selection : null}
                onSelect={(selection) => onSelect({ area, ...selection })}
                editing={editing}
                onEdit={onEdit}
                onKeyDown={(e) => { if (e.key === "Tab") return }}
                onChange={value => {
                  if (value.ops[0].insert !== "\t\n") onChange(_.set(content, [y, x], { ...cell, content: value }))
                }}
                {...cell}
              />
            );
          })}
        </tr>
      ))}
    </Container>
  );
};

Rows.Type = {};
_.each(['Head', 'Body'], (type) => {
  Rows.Type[type] = type;
  Rows[type] = (props) => <Rows {...{ [_.toLower(type)]: true }} {...props} />;
});

Rows.Container = { head: 'thead', body: 'tbody' };
Cell.Component = {
  head: 'th', body: 'td',
  border: forwardRef((props, ref) => <td ref={ref} css={css` text-align: center; `} {...props} />)
};

const Border = ({ x = -1, y = -1, notation, orientation, selection, onSelect }) => (
  <Cell
    editable
    area="border"
    x={x} y={y}
    content={notation}
    selection={selection}
    onSelect={(selection) => onSelect({ ...selection, orientation })}
  />
);

Border.Column = (props) => <Border {...props} notation={Border.Column.Notation(props.x)} orientation="column" />;
Border.Row = (props) => <Border {...props} notation={props.y + 1} orientation="row" />;

Border.Column.Notation = (index) => {
  const letter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[index % 26];
  return index >= 26 ? Notation(Math.floor(index / 26) - 1) + letter : letter;
};

const Default = [
  {
    type: Rows.Type.Head,
    rows: [
      [
        { content: 'Head One', style: { caps: true } },
        { content: 'Head Two', style: { caps: true } },
        { content: 'Head Three', style: { caps: true } },
        { content: 'Head Four', style: { caps: true } },
        { content: 'Head Five', style: { caps: true } }
      ]
    ]
  },
  {
    type: Rows.Type.Body,
    rows: [
      [
        { content: '1 one' },
        { content: '1 two' },
        { content: '1 three' },
        { content: '1 four' },
        { content: '1 five' }
      ],
      [
        { content: '2 one' },
        { content: '2 two' },
        { content: '2 three' },
        { content: '2 four' },
        { content: '2 five' }
      ],
      [
        { content: '3 one' },
        { content: '3 two' },
        { content: '3 three' },
        { content: '3 four' },
        { content: '3 five' }
      ],
      [
        { content: '4 one' },
        { content: '4 two' },
        { content: '4 three' },
        { content: '4 four' },
        { content: '4 five' }
      ],
      [
        { content: '5 one' },
        { content: '5 two' },
        { content: '5 three' },
        { content: '5 four' },
        { content: '5 five' }
      ]
    ]
  }
];

const Controller = ({ editable, contents, value, onChange }) => {
  const [{ selection, editing }, setState] = useState({ selection: false, editing: false });
  const [clipBoard, setClipboard] = useState(null)
  const ref = useRef();

  let content = _.isArray(value) ? value : (_.isArray(contents) ? contents : Default);

  if (!_.first(content)?.rows) content = Default;
  content = _.filter(content, (value) => !_.isEmpty(value.rows));

  const size = {
    x: _.first(_.first(content).rows).length - 1,
    y: _.reduce(content, (result, block) => result + block.rows.length, 0) - 1
  };

  let offset = 0;
  const ranges = selection?.from && selection?.to && _.reduce(content, (result, block, index) => {
    const { from, to } = selection;

    const count = block.rows.length;
    const real = { from: offset, to: offset + (count - 1) };

    if ((from.y >= real.from && from.y <= real.to) || (to.y >= real.from && to.y <= real.to)) {
      result.push({
        block: index,
        from: { x: from.x, y: Math.max(from.y - offset, 0) },
        to: { x: to.x, y: Math.min(to.y - offset, Math.max(from.y - offset, count - 1)) }
      });
    }

    offset += count;
    return result;
  }, []);

  const change = (callback) => onChange(callback(_.cloneDeep(content)));
  change.ranges = (callback) => change((cells) => _.reduce(ranges, (result, { block, from, to }) => {
    for (let y = from.y; y <= to.y; y++) {
      for (let x = from.x; x <= to.x; x++) {
        result[block].rows[y][x] = callback({
          x, y,
          from, to, block,
          cell: cells[block].rows[y][x]
        });
      }
    }

    return result;
  }, cells));

  const edit = (coordinates) => editable && setState({ editing: coordinates, selection: false });
  const select = (selection) => {
    if (!editable) return;

    if (selection.area == 'border') {
      if (selection.orientation == 'column') {
        selection.pin.y = 0;
        selection.target.y = size.y;
      } else if (selection.orientation == 'row') {
        selection.pin.x = 0;
        selection.target.x = size.x;
      }
    }

    if (selection.pin && selection.target) {
      selection.from = {
        x: Math.min(selection.pin.x, selection.target.x),
        y: Math.min(selection.pin.y, selection.target.y)
      };
      selection.to = {
        x: Math.max(selection.pin.x, selection.target.x),
        y: Math.max(selection.pin.y, selection.target.y)
      };
    }

    setState({ editing: false, selection });
  };

  useEffect(() => {
    if (!editable) return;

    document.addEventListener('mousedown', (event) => {
      if (ref.current && !ref.current.contains(event.target)) setState({
        selection: false,
        editing: false
      });
    });

    document.addEventListener('mouseup', () => {
      if (selection?.active) select({
        ...selection,
        active: false
      });
    });
  }, []);

  const layout = {
    insert: {
      column: (offset) => change((cells) => {
        const { x } = _.first(ranges)?.from;

        const blankCell = { content:'' };
        const blankCellWithCaps = { content:'', style: { caps: true }};

        for (let block = 0; block < cells.length; block++) {
          for (let row = 0; row < cells[block].rows.length; row++) {
            cells[block].rows[row].splice(offset > 0 ? x + 1 : x, 0, block == 0 ? blankCellWithCaps : blankCell);
          }
        }

        return cells;
      }),
      row: (offset) => change((cells) => {
        const { block, from: { y } } = _.first(ranges);
        cells[block].rows.splice(offset > 0 ? y + 1 : y, 0, Array(_.first(cells[block].rows).length).fill({ content: '' }))
        return cells;
      })
    },
    delete: {
      columns: () => change((cells) => {
        const { from, to } = _.first(ranges);

        for (let block = 0; block < cells.length; block++) {
          for (let row = 0; row < cells[block].rows.length; row++) {
            cells[block].rows[row].splice(from.x, (to.x - from.x) + 1);
          }
        }

        return cells;
      }),
      rows: () => change((cells) => {
        _.each(ranges, ({ block, from, to }) => cells[block].rows.splice(from.y, (to.y - from.y) + 1));
        return cells;
      })
    },
    merge: () => change.ranges(({ cell, x, y, from }) => x == from.x && y == from.y
      ? { ...cell, merge: { x: selection.to.x - selection.from.x, y: selection.to.y - selection.from.y } }
      : _.omit(cell, 'merge')),
    unmerge: () => change.ranges(({ cell, x, y }) => _.omit(cell, 'merge')),
    wipe: () => change.ranges(({ cell }) => ({ ...cell, content: '' })),
    clear: () => change.ranges(({ cell }) => _.omit(cell, 'style'))
  };

  const style = (style, value = true) => () => change.ranges(({ cell }) => ({
    ...cell,
    style: value === true && cell.style?.[style] ? _.omit(cell.style, style) : {
      ...cell.style,
      [style]: value
    }
  }));

  let before = 0;

  const onKeyDown = async (e) => {
    const selectedContent = content.find((item) => item.type?.toLowerCase() === 'body') || {};
    const { rows } = selectedContent;
    const min_x = 0;
    const min_y = 0;
    const max_x = rows[0].length - 1;
    const max_y = rows.length;

    const updateSelection = (x, y) => {
      selection.from = { x: selection.from.x + x, y: selection.from.y + y };
      selection.pin = { x: selection.pin.x + x, y: selection.pin.y + y };
      selection.target = { x: selection.target.x + x, y: selection.target.y + y };
      selection.to = { x: selection.to.x + x, y: selection.to.y + y };
      setState({ selection });
    };

    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        if (selection?.target?.y > min_y) {
          updateSelection(0, -1);
        }
        break;
      case "ArrowRight":
        e.preventDefault();
        if (selection?.target?.x < max_x) {
          updateSelection(1, 0);
        }
        break;
      case "ArrowDown":
        e.preventDefault();
        if (selection?.target?.y < max_y) {
          updateSelection(0, 1);
        }
        break;
      case "ArrowLeft":
        e.preventDefault();
        if (selection?.target?.x > min_x) {
          updateSelection(-1, 0);
        }
        break;
      case "Tab":
        e.preventDefault();
        if (selection) {
          if (selection.target.x < max_x) {
            updateSelection(1, 0);
          } else if (selection.target.y < max_y) {
            selection.from = { x: 0, y: selection.from.y + 1 }
            selection.pin = { x: 0, y: selection.pin.y + 1 }
            selection.target = { x: 0, y: selection.target.y + 1 }
            selection.to = { x: 0, y: selection.to.y + 1 }
            setState({ selection });
          }
        } else if (editing) {
          const { x, y } = editing;
          editing.x = x < max_x ? x + 1 : 0;
          editing.y = x < max_x ? y : y + 1;
          setState({ editing });
        }
        break;
      case "c":
        if (e.ctrlKey || e.metaKey) {
          e.preventDefault();
          if (selection) {
            await navigator.clipboard.writeText('')

            let fy = selection.from.y
            let tx = selection.to.x
            let ty = selection.to.y

            let copiedData = { rows: [] }
            for (let i = 0; fy <= ty; fy++, i++) {
              copiedData.rows.push([])
              for (let j = 0, fx = selection.from.x; fx <= tx; fx++, j++) {
                const contentIndex = fy > 0 ? 1 : 0
                if (content[contentIndex] && content[contentIndex].rows) {
                  copiedData.rows[i].push(
                    content[contentIndex].rows[fy - contentIndex][fx]
                  )
                }
              }
            }
            setClipboard(copiedData)
            setState({ selection });
          } else if (editing) {
            if (editing.area === "head") {
              const copiedData = content[0].rows[editing.y][editing.x].content.ops[0].insert.trim()
              await navigator.clipboard.writeText(copiedData)
            } else if (editing.area === "body") {
              const copiedData = content[1].rows[editing.y - 1][editing.x].content.ops[0].insert.trim()
              await navigator.clipboard.writeText(copiedData)
            }
          }
        }
        break;
      case "v":
        if (e.ctrlKey || e.metaKey) {
          e.preventDefault();
          await navigator.clipboard.readText().then(res => {
            if (!res) {
              return clipBoard.rows
            } else {
              let rows = res.split("\n")
              rows.map((item, index) => {
                rows[index] = item.split("\t")
              })
              rows.map(row => {
                row.map((item, index) => {
                  row[index] = { 'content': item }
                })
              })
              return rows
            }
          }).then((rows) => {
            pasteContent(rows)
          })
          setState({ selection });
        }
        break;
      default:
        break;
    }
  };

  const pasteContent = (copiedData) => {
    try {
      if (selection) {
        let y = selection.from.y - 1
        if (selection.area === "head") {
          copiedData.map((row, index) => {
            let x = selection.from.x
            if (index === 0) {
              row.map(item => {
                content[0].rows[0][x] = item
                x++
              })
            }
            else {
              row.map(item => {
                content[1].rows[y][x] = item
                x++
              })
            }
            y++
          })
        } else {
          copiedData.map(row => {
            let x = selection.from.x
            row.map(item => {
              content[1].rows[y][x] = item
              x++
            })
            y++
          })
        }
      } else if (editing) {
        let y = editing.y - 1
        if (editing.area === "head") {
          copiedData.map((row, index) => {
            let x = editing.x
            if (index === 0) {
              row.map(item => {
                content[0].rows[0][x] = item
                x++
              })
            }
            else {
              row.map(item => {
                content[1].rows[y][x] = item
                x++
              })
            }
            y++
          })
        } else {
          copiedData.map(row => {
            let x = editing.x
            row.map(item => {
              content[1].rows[y][x] = item
              x++
            })
            y++
          })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getStylesOnSelectedCell = () => {
    try {
      if (selection && selection.area !== "border") {
        const selectedContent = content.find((item) => item.type?.toLowerCase() === selection.area) || {};
        if (selection.area === "head") {
          return selectedContent.rows[selection.from.y]?.[selection.from.x]?.style
        }
        return selectedContent.rows[selection.from.y - 1]?.[selection.from.x]?.style
      }
    } catch (e) {
      console.log(e)
    }
  }

  const textColorIcon = (UI) => {
    let icon = UI.Theme.icons?.tools?.textColor || "Pink";
    return UI.Images.Tools[icon];
  };

  return (
    <div css={css`position: relative; ${editable && css`* > td, tr { user-select: none; }`}`} ref={ref}>
      <Table UI={UI} editable={editable} tabIndex="-1" onKeyDown={onKeyDown}>
        <colgroup>{editable && <col />}{_.first(_.first(content).rows).map(() => <col />)}</colgroup>

        {editable &&
          <thead css={css`border-top: 0px;`}>
            <tr>
              <td css={css`border-top: 0px;`} onClick={() => select({ from: { x: 0, y: 0 }, to: size })}></td>
              {_.first(_.first(content).rows).map((value, index) => <Border.Column x={index} selection={selection} onSelect={select} />)}
            </tr>
          </thead>
        }

        {content.map((block, index) => {
          const Type = Rows[block.type];
          const Output = <Type {...{
            index, editable, editing,
            selection, layout,
            content: block.rows,
            onSelect: select,
            onEdit: edit,
            onChange: (rows) => onChange(_.set(content, index, { ...block, rows })),
            prior: index == 0 ? 0 : content[index - 1].rows.length, before,
            container: ref
          }} />;

          before += block.rows.length;
          return Output;
        })}
      </Table>
      {selection && !selection.active && (
        <UI.Flyout position={selection.position}>
          <IconWrapper title="Bold" className={getStylesOnSelectedCell()?.bold ? "active" : null}
            onClick={style('bold')}>
            <UI.Icon image={UI.Images.Tools.Bold} />
          </IconWrapper>
          <IconWrapper title="Italic" className={getStylesOnSelectedCell()?.italic ? "active" : null} onClick={style('italic')}>
            <UI.Icon image={UI.Images.Tools.Italic} />
          </IconWrapper>
          <IconWrapper title="Brand Color" className={getStylesOnSelectedCell()?.pink ? "active" : null} onClick={style('pink')}>
            <UI.Icon image={textColorIcon(UI)} />
          </IconWrapper>
          <UI.Belt css={css`height: 24px;`}>
            <IconWrapper title="Background Color" className={getStylesOnSelectedCell()?.backgroundColor ? "active" : null}>
              <UI.Icon image={UI.Images.Tools.ColorFill} />
            </IconWrapper>
            <UI.Belt.Action onClick={style("backgroundColor", "#FFFFFF")} title="#FFFFFF">
              <BeltOption className={getStylesOnSelectedCell()?.backgroundColor === "#FFFFFF" ? "active" : null}>
                #FFFFFF <ColorPreview color="#FFFFFF" />
              </BeltOption>
            </UI.Belt.Action>
            <UI.Belt.Action onClick={style("backgroundColor", "#F8F8F8")} title="#F8F8F8">
              <BeltOption className={getStylesOnSelectedCell()?.backgroundColor === "#F8F8F8" ? "active" : null}>
                #F8F8F8 <ColorPreview color="#F8F8F8" />
              </BeltOption>
            </UI.Belt.Action>
            <UI.Belt.Action onClick={style("backgroundColor", "#E6E6E6")} title="#E6E6E6">
              <BeltOption className={getStylesOnSelectedCell()?.backgroundColor === "#E6E6E6" ? "active" : null}>
                #E6E6E6 <ColorPreview color="#E6E6E6" />
              </BeltOption>
            </UI.Belt.Action>
            <UI.Belt.Action onClick={style("backgroundColor", null)} title="Clear Background Color">
              <BeltOption>None <ColorPreview /></BeltOption>
            </UI.Belt.Action>
          </UI.Belt>
          <Spacer />
          {selection.area === 'head' && (
            <>
              <IconWrapper
                title="Uppercase"
                className={getStylesOnSelectedCell()?.caps ? 'active' : null}
                onClick={style('caps')}
              >
                <UI.Icon image={UI.Images.Tools.TextCase} />
              </IconWrapper>
              <Spacer />
            </>
          )}
          <IconWrapper title="Left Align" className={getStylesOnSelectedCell()?.align === "left" ? "active" : null} onClick={style('align', 'left')}>
            <UI.Icon image={UI.Images.Tools.AlignLeft} />
          </IconWrapper>
          <IconWrapper title="Center Align" className={getStylesOnSelectedCell()?.align === "center" ? "active" : null} onClick={style('align', 'center')}>
            <UI.Icon image={UI.Images.Tools.AlignCenter} />
          </IconWrapper>
          <IconWrapper title="Right Align" className={getStylesOnSelectedCell()?.align === "right" ? "active" : null} onClick={style('align', 'right')}>
            <UI.Icon image={UI.Images.Tools.AlignRight} />
          </IconWrapper>
          <Spacer />
          <UI.Belt css={css`height: 24px;`}>
            <IconWrapper title="Merge Options">
              <UI.Icon image={UI.Images.Tools.MergeOptions} />
            </IconWrapper>
            <UI.Belt.Action onClick={layout.merge}>
              <BeltOption>Merge Cells</BeltOption>
            </UI.Belt.Action>
            <UI.Belt.Action onClick={layout.unmerge}>
              <BeltOption>Unmerge Cells</BeltOption>
            </UI.Belt.Action>
          </UI.Belt>
          <Spacer />
          <UI.Belt css={css`height: 24px;`}>
            <IconWrapper title="Insert Cells">
              <UI.Icon image={UI.Images.Tools.AddCell} />
            </IconWrapper>
            <UI.Belt.Action onClick={() => layout.insert.column(-1)}>
              <BeltOption>Insert Column Before</BeltOption>
            </UI.Belt.Action>
            <UI.Belt.Action onClick={() => layout.insert.column(1)}>
              <BeltOption>Insert Column After</BeltOption>
            </UI.Belt.Action>
            <UI.Belt.Action onClick={() => layout.insert.row(-1)}>
              <BeltOption>Insert Row Before</BeltOption>
            </UI.Belt.Action>
            <UI.Belt.Action onClick={() => layout.insert.row(1)}>
              <BeltOption>Insert Row After</BeltOption>
            </UI.Belt.Action>
          </UI.Belt>
          <IconWrapper onClick={layout.clear} title="Clear Formatting">
            <UI.Icon image={UI.Images.Tools.Eraser} />
          </IconWrapper>
          <UI.Belt css={css`height: 24px;`}>
            <IconWrapper title="Delete Cells">
              <UI.Icon image={UI.Images.Tools.Trash} />
            </IconWrapper>
            <UI.Belt.Action onClick={layout.delete.rows}>
              <BeltOption>Delete Rows</BeltOption>
            </UI.Belt.Action>
            <UI.Belt.Action onClick={layout.delete.columns}>
              <BeltOption>Delete Columns</BeltOption>
            </UI.Belt.Action>
            <UI.Belt.Action onClick={layout.wipe}>
              <BeltOption>Delete Content</BeltOption>
            </UI.Belt.Action>
          </UI.Belt>
        </UI.Flyout>
      )}
    </div>
  );
};

export default define`Table`('0.0.1')({
  view: Controller,
  edit: (props) => <Controller editable {...props} />
});
