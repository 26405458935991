import Conditional from './Conditional';

const HeroSubpage = ({ prehead, h1, posthead, backgroundImgURL }) => (
  <section
    className="hero-video-background hero-bg hero-subpage"
    style={backgroundImgURL && { backgroundImage: `url(${backgroundImgURL})` }}
  >
    <div className="hero internal-page-heading">
      <div className="main">
        <Conditional if={prehead}>
          <span className="prehead tier3">{prehead}</span>
        </Conditional>

        <h1 className="tier3" dangerouslySetInnerHTML={{ __html: h1 }}></h1>

        <Conditional if={posthead}>
          <span className="posthead">{posthead}</span>
        </Conditional>
      </div>

      <div className="fill top-fill"></div>
      <div className="fill left-fill"></div>
      <div className="fill right-fill"></div>
    </div>
  </section>
);

export default HeroSubpage;
