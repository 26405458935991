import { useState, useEffect, useRef } from 'react';
import { jsx, css } from '@emotion/react';
import styled from "@emotion/styled"
import { connectLocation } from "../location/LocationConnectors";
import Cta from '../OC/oc-cta';

const PreviewContainer = styled.div`
	display: inline-block;
	position: relative;
	width: 100%;

	.widgetContainer {
		margin-bottom: 16px;
		border-bottom: 2px solid black;

		@media(min-width: 768px) {
			margin-bottom: 32px;
		}

		@media(min-width: 1200px) {
			margin-bottom: 64px;
		}
	}
`;

const inlineGrey = css`
	background: #f8f8f8;
	padding: 2px;
	display: inline;
	font-family: monospace;
	white-space: pre;
`;

const greyBg = css`
	background: #f8f8f8;
	padding: 16px;
	margin-bottom: 24px;
	overflow-x: auto;
`;

const ctaContainer = css`
	display: flex;
	flex-direction: column;
	width: 100%;

	@media(min-width: 768px) {
		width: 60%;
	}

	@media(min-width: 1200px) {
		flex-direction: row;
		width: 100%;
	}

	>:first-of-type {
		margin-bottom: 16px;

		@media(min-width: 1200px) {
			margin-bottom: 0;
			margin-right: 16px;
		}
	}
`;

const Widget = connectLocation(({shingleLine, shingleView, widgetLayout, houseStyle, t}) => {
	const iFrame = document.querySelector('.oc_shingle_view iframe');
	const pymChildId = document.querySelector('.oc_shingle_view')?.getAttribute('id');

	const jsCode = '<script src="https://apis.owenscorning.com/client/widget.js" async></script>';
	const bodyTag = `<body>`;
	const closingBodyTag = `</body>`;
	let widgetUrl = `${window.location.origin}/public_widgets/shingle/${shingleLine}?view=${shingleView}&style=${houseStyle}&layout=${widgetLayout}&pymChildId=${pymChildId}`;
	let widgetCode = `<div\n class="oc_shingle_view"\n data-shingle="${shingleLine}"\n data-view="${shingleView}"\n data-layout="${widgetLayout}" \n data-style="${houseStyle}"\n>\n</div>`;

	const initialRender = useRef(true);

	useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false;
		} else {
			setTimeout(() => {
				iFrame.src = widgetUrl;
			}, 500);
		}
	}, [shingleLine, shingleView, widgetLayout, houseStyle]);

  const emailBody = [
    t('shingle_widget.email.intro1'),
    t('shingle_widget.email.intro2'),
    ,
    t('shingle_widget.copyjs_step'),
    `${t('shingle_widget.copyjs_instructions')} ${bodyTag} ${t('shingle_widget.copyjs_instructions2')} ${closingBodyTag} ${t('shingle_widget.copyjs_instructions3')}`,
    ,
    jsCode,
    ,
    t('shingle_widget.copy_code'),
    t('shingle_widget.copy_code_p1'),
    t('shingle_widget.copy_code_p2'),
    ,
    widgetCode,
    ,
    t('shingle_widget.email.link')
  ].join("\r\n")

  const ctaHref = `mailto:?subject=${encodeURIComponent(t('shingle_widget.email.subject'))}&body=${encodeURIComponent(emailBody)}`

	return (
		<PreviewContainer>
			<h4>{t('shingle_widget.widget_preview_heading')}</h4>
			<p>{t('shingle_widget.widget_preview_p')}</p>

			<div className="widgetContainer" aria-label="shingle widget mockup view" tabIndex="0" >
				<div className="oc_shingle_view" data-shingle={shingleLine} data-src={widgetUrl} data-view={shingleView} data-layout={widgetLayout} data-style={houseStyle}></div>
			</div>

			<h2>{t('shingle_widget.copyjs_heading')}</h2>
			<h4>{t('shingle_widget.copyjs_step')}</h4>

			<p>{t('shingle_widget.copyjs_instructions')} <span css={inlineGrey}>{bodyTag}</span> {t('shingle_widget.copyjs_instructions2')} <span css={inlineGrey}>{closingBodyTag}</span> {t('shingle_widget.copyjs_instructions3')}</p>

			<div css={greyBg}>
				<pre>
					{jsCode}
				</pre>
			</div>

			<h4>{t('shingle_widget.copy_code')}</h4>
			<p>{t('shingle_widget.copy_code_p1')}</p>
			<p>{t('shingle_widget.copy_code_p2')}</p>

			<div id="code-block-shingle-widget" css={greyBg}>
				<pre>
					{widgetCode}
				</pre>
			</div>

			<div css={ctaContainer}>
				<button id="shingle-line-button-copy" className="oc-cta-button copy-button" data-clipboard-target="#code-block-shingle-widget" data-track="copy" data-track-destination="Shingles Widget | TruDefinition Duration Designer">{t('shingle_widget.copy_to_clipboard')}</button>
				<Cta href={ctaHref} id="email-button-shingle-line" data-track="email-widget" data-track-destination="Shingles Widget | TruDefinition Duration Designer">{t('shingle_widget.email_widget')}</Cta>
			</div>
		</PreviewContainer>
	);
});

export default Widget;
