import { jsx, css } from '@emotion/react';

const sectionStyles = ({fluid, fluidTablet, fluidMobile, border}) => css`
  margin: 30px auto;
  max-width: 400px;

  @media(min-width: 768px) {
    max-width: 768px;

    ${(fluidTablet === true || fluid === true) &&`
      max-width: none;
    `}
  }

  @media(min-width: 1200px) {
    margin: 70px auto;
    max-width: 1170px;

    ${fluid === true &&`
      max-width: none;
    `}
  }

  ${border === true &&`
    border-bottom: 2px solid black;
    padding-bottom: 20px;

    @media screen and (min-width: $screen-sm) {
      padding-bottom: 30px;
    }

    @media screen and (min-width: $screen-lg) {
      padding-bottom: 70px;
    }
  `}
`

const Section = ({ children, fluid, fluidTablet, fluidMobile, border }) => (
  <section css={[sectionStyles ({fluid, fluidTablet, fluidMobile, border})]}>
    {children}
  </section>
);

export default Section;
