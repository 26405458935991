import { useState, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import styled from "@emotion/styled"
import { connectLocation } from "../location/LocationConnectors";
import _ from 'lodash';

import Section from '../OC/oc-section';
import Image from '../OC/oc-image';

import Widget from './Widget';
import ImageWithModal from './ImageWithModal';
import AlertMessage from '../OC/oc-alert-message';

const WidgetOptionsContainer = styled.div`
  background-color: #E6E6E6;
  display: inline-block;
  margin-bottom: 2em;
  padding: 1em;
  position: relative;
  width: 100%;
`;

const Instructions = styled.div`
  display: inline-block;
  margin-bottom: 1em;
  position: relative;
  width: 100%;
`;

const Form = styled.form`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const Fieldset = styled.fieldset`
  display: inline-block;
  margin-bottom: 1em;
  position: relative;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: calc(50% - 1em);

    &:nth-last-of-type(2) {
      margin-bottom: 0;
    }
  }
  label{
    max-width: 100%;
  }
  select{
    width: 100%
  }
`;

const houseView = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  label {
    width: 50%;
    padding: 0 15px;

    &:nth-of-type(odd) {
      padding-left: 0;
    }

    &:nth-of-type(even) {
      padding-right: 0;
    }
  }
`;

const LayoutBody = styled.p`
  margin-bottom: 8px;
`;

const LayoutDisclaimer = styled.p`
  margin-top: 8px;
  font-size: 12px;
`;

const alertSpacing = css`
  margin-top: 8px;
`;

const WidgetConfigurator = connectLocation(({t, shingles}) => {
  const [shingleLine, setShingleLine] = useState('trudefinition-duration');
  const [shingleView, setShingleView] = useState('house');
  const [widgetLayout, setWidgetLayout] = useState('row');
  const [houseStyle, setHouseStyle] = useState('default');
  const shingleLinesWithHouseStyleOptions = [
    'oakridge',
    'trudefinition-duration',
    'trudefinition-duration-designer',
    'trudefinition-duration-flex',
    'trudefinition-oakridge'
  ];

  const shingleLineUnavailable = (shingleUid) => {
    return shingles.find(shingle => shingle.uid === shingleUid)?.unavailable;
  }

  const updateShingleLine = (e) => {
    setShingleLine(e.target.value);

    if (!shingleLinesWithHouseStyleOptions.includes(e.target.value)) {
      setHouseStyle("default");
    }
  }

  const updateShingleView = (e) => {
    setShingleView(e.target.value);
  }

  const updateWidgetLayout = (e) => {
    setWidgetLayout(e.target.value);
  }

  const updateHouseStyle = (e) => {
    setHouseStyle(e.target.value);
  }

  const deactivatedShingles = ['TruDefinition® WeatherGuard® HP Shingles', 'Duration® Premium COOL Shingles']
  const filteredShingles = shingles.filter(shingle => !deactivatedShingles.includes(shingle.name) && !shingle.discontinued);

  return (
    <Section>
      <WidgetOptionsContainer>
        <Instructions>
          <h4>{t('shingle_widget.instructions_heading')}</h4>
          <p>{t('shingle_widget.instructions_intro_p1')}</p>
          <p>{t('shingle_widget.instructions_intro_p2')}</p>
        </Instructions>

        <Form className="oc-form">
          <Fieldset>
            <legend className="h4">{t('shingle_widget.select_shingle_line')}</legend>
            <label className="select-container">
              <select
                id="shingle-line-select"
                defaultValue={shingleLine}
                onChange={updateShingleLine}
                data-track="select-by"
                data-track-select-category="Shingle Line"
                data-track-select-option={shingleLine}
                aria-label="Select a Shingle Line"
              >
                {_.orderBy(filteredShingles, 'proper_name').map((shingle, index) => (
                  <option key={index}
                          data-shingle={shingle.uid}
                          data-analytic-name={shingle.proper_name}
                          value={shingle.uid}>
                    {shingle.full_proper_name}
                  </option>
                ))}
              </select>
            </label>

            {shingleLineUnavailable(shingleLine) &&
              <div css={alertSpacing}>
                <AlertMessage title={['supreme', 'woodmoor', 'woodcrest', 'berkshire'].includes(shingleLine) ? t('availability.title') : t('availability.limited_availability_title')} message={t('availability.message_w_link')} dismissable={false} type='warning' alertIcon={true} textSize="13px" />
              </div>
            }
          </Fieldset>

          <Fieldset>
            <legend className="h4">{t('shingle_widget.choose_default_view')}</legend>
            <p>{t('shingle_widget.choose_default_view_p')}</p>

            <div className="radio-check" onChange={updateShingleView}>
              <label htmlFor="houseView">
                <input
                  data-track-option="House View"
                  data-track="toggle"
                  defaultChecked
                  id="houseView"
                  name="defaultView"
                  type="radio"
                  value="house"
                />
                {t('shingle_widget.house_view')}
              </label>

              <label htmlFor="shingleView">
                <input
                  data-track-option="Shingle View"
                  data-track="toggle"
                  id="shingleView"
                  name="defaultView"
                  type="radio"
                  value="shingle"
                />
                {t('shingle_widget.shingle_view')}
              </label>
            </div>
          </Fieldset>

          {shingleLinesWithHouseStyleOptions.includes(shingleLine) &&
            <Fieldset>
              <legend className="h4">{t('shingle_widget.select_house_style')}</legend>
              <p>{t('shingle_widget.select_house_style_p')}</p>

              <div className="radio-check with-image">
                <div css={houseView} onChange={updateHouseStyle}>
                  <label htmlFor="defaultHouse">
                    <div className="input-container">
                      <input
                        data-track-option="Default House"
                        data-track="toggle"
                        defaultChecked
                        id="defaultHouse"
                        name="houseStyle"
                        type="radio"
                        value="default"
                      />
                      {t('shingle_widget.default_house')}
                    </div>

                    <ImageWithModal src="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_60,w_500/h_300,c_crop/v1615904691/roofing/shingle-widget/default-house.jpg" modalImg="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_60/v1615904691/roofing/shingle-widget/default-house.jpg" alt="Default House" title={t('shingle_widget.default_house')} />
                  </label>

                  <label htmlFor="ranchStyle">
                    <div className="input-container">
                      <input
                        data-track-option="Ranch Style"
                        data-track="toggle"
                        id="ranchStyle"
                        name="houseStyle"
                        type="radio"
                        value="ranch"
                      />
                      {t('shingle_widget.ranch_style')}
                    </div>

                    <ImageWithModal src="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_60,w_500,h_312/h_250,c_crop/v1615904332/roofing/shingle-widget/ranch-view.jpg" modalImg="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_60/v1615904332/roofing/shingle-widget/ranch-view.jpg" alt="Ranch House" title={t('shingle_widget.ranch_style')} />
                  </label>

                  <label htmlFor="twoStory1">
                    <div className="input-container">
                      <input
                        data-track-option="Two Story 1"
                        data-track="toggle"
                        id="twoStory1"
                        name="houseStyle"
                        type="radio"
                        value="two-story-1"
                      />
                      {t('shingle_widget.two_story1')}
                    </div>

                    <ImageWithModal src="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_60,w_500,h_312/h_250,c_crop/v1615904442/roofing/shingle-widget/two-story-1.jpg" modalImg="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_60/v1615904442/roofing/shingle-widget/two-story-1.jpg" alt="Two Story House #1" title={t('shingle_widget.two_story1')} />
                  </label>

                  <label htmlFor="twoStory2">
                    <div className="input-container">
                      <input
                        data-track-option="Two Story 2"
                        data-track="toggle"
                        id="twoStory2"
                        name="houseStyle"
                        type="radio"
                        value="two-story-2"
                      />
                      {t('shingle_widget.two_story2')}
                    </div>

                    <ImageWithModal src="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_60,w_500,h_312/h_250,c_crop/v1615904311/roofing/shingle-widget/two-story-2.jpg" modalImg="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,q_60/v1615904311/roofing/shingle-widget/two-story-2.jpg" alt="Two Story House #2" title={t('shingle_widget.two_story2')} />
                  </label>
                </div>
              </div>
            </Fieldset>
          }

          <Fieldset>
            <legend className="h4">{t('shingle_widget.select_widget_layout')}</legend>
            <LayoutBody>{t('shingle_widget.select_widget_layout_p')}</LayoutBody>
            <LayoutDisclaimer>{t('shingle_widget.select_widget_layout_dis')}</LayoutDisclaimer>

            <div className="radio-check horizontal with-image" onChange={updateWidgetLayout}>
              <label htmlFor="sideBySide">
                <div className="input-container">
                  <input
                    data-track-option="Side by Side"
                    data-track="toggle"
                    defaultChecked
                    id="sideBySide"
                    name="widgetLayout"
                    type="radio"
                    value="row"
                  />
                  {t('shingle_widget.side_by_side')}
                </div>
                <Image src="https://imagecdn.owenscorning.com/image/upload/v1737996119/roofing/shingle-widget/widget-horizontal-view.png" alt="Horizontal View" />
              </label>

              <label htmlFor="stackedView">
                <div className="input-container">
                  <input
                    data-track-option="Stacked"
                    data-track="toggle"
                    id="stackedView"
                    name="widgetLayout"
                    type="radio"
                    value="stacked"
                   />
                  {t('shingle_widget.stacked_view')}
                </div>
                <Image src="https://imagecdn.owenscorning.com/image/upload/v1737996119/roofing/shingle-widget/widget-stacked-view.png" alt="Stacked View" />
              </label>
            </div>
          </Fieldset>
        </Form>
      </WidgetOptionsContainer>

      <Widget shingleLine={shingleLine} shingleView={shingleView} widgetLayout={widgetLayout} houseStyle={houseStyle} />
    </Section>
  );
});

export default WidgetConfigurator;
