import { useState } from 'react';
import { jsx, css } from '@emotion/react';
import Modal from '../Modal';
import Image from '../OC/oc-image';

const imageWithModalStyles = css`
	position: relative;

	img {
		display: block;
	}

	.fa {
		background: rgba(255,255,255,.5);
		padding: 10px 30px 10px 10px;
		color: #585858;
		position: absolute;
		bottom: 0;
		right: 0;
		font-size: 20px;

		&:hover {
			background: rgba(212, 15, 125, .5);
			color: white;
			cursor: pointer;
		}
	}
`;

const ImageWithModal = ({src, modalImg, alt, title}) => {
	const [openModal, setOpenModal] = useState(false);

	return (
		<>
			<div css={imageWithModalStyles} >
				<span className="fa fa-arrows-alt" onClick={() => setOpenModal(true)}></span>
				<Image src={src} alt={alt} />
			</div>

			<Modal open={openModal} onClose={() => setOpenModal(false)} className="no-borders gallery-modal" >
				<Image src={modalImg ? modalImg : src} alt={alt} />
				<h3 className="image-name">{title}</h3>
			</Modal>
		</>
	);
}

export default ImageWithModal;
