import { useState } from 'react';
import { jsx, css } from '@emotion/react';
import { Provider } from 'react-redux';
import {connectLocation} from '../location/LocationConnectors';
import { store } from '../Store';

import HeroSubpage from '../HeroSubpage';
import Section from '../OC/oc-section';
import WidgetConfigurator from './WidgetConfigurator'

const ShingleWidget = connectLocation(({t, shingles}) => {

  return (
    <>
      <HeroSubpage prehead={t('shingle_widget.hero_prehead')} h1={t('shingle_widget.hero_heading')} backgroundImgURL={'https://imagecdn.owenscorning.com/ocimages/image/upload/v1615481226/roofing/shingle-widget/hero-shingle-widget.png'} />

      <Section>
        <h2>{t('shingle_widget.intro_heading')}</h2>
        <p>{t('shingle_widget.intro_text')}</p>
      </Section>

      <WidgetConfigurator shingles={shingles}/>
    </>
  );
});

const Index = ({shingles}) => (
  <Provider store={ store }>
    <ShingleWidget shingles={shingles}/>
  </Provider>
);

export default Index;
